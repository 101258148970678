// import Logo from 'assets/images/project/cb.png'; // Adjust the path to your logo
import Logo1 from 'assets/images/project/cb_nav_logo.webp'; // Adjust the path to your logo

import React, { useState, useEffect, useRef } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useTheme,
  useMediaQuery,
  Box,
  Menu, MenuItem
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';

function Navbar() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const dropdownRef = useRef(null);

  const toggleDrawer = () => {
    setDrawerOpen(prevOpen => {
      const newOpen = !prevOpen;
      return newOpen;
    });

  };

  const handleMouseOver = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        handleClose();
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const drawerItems = (
    <List sx={{ width: '100%', paddingTop: '20px' }}>
        <ListItem button onClick={() => {toggleDrawer(); navigate('/');}} sx={{ justifyContent: 'center', mt: 4  }}>
            <ListItemText
            primary="HOME"
            primaryTypographyProps={{ sx: { color: 'white !important !important', textAlign: 'center' } }}
            />
        </ListItem>
        <ListItem button onClick={() => {toggleDrawer(); navigate('/actors');}} sx={{ justifyContent: 'center', mt: 3  }}>
            <ListItemText
            primary="ACTORS"
            primaryTypographyProps={{ sx: { color: 'white !important', textAlign: 'center' } }}
            />
        </ListItem>
        <ListItem button onClick={() => {toggleDrawer(); navigate('/submission');}} sx={{ justifyContent: 'center', mt: 3  }}>
            <ListItemText
            primary="SUBMISSION"
            primaryTypographyProps={{ sx: { color: 'white !important !important', textAlign: 'center' } }}
            />
        </ListItem>
        <ListItem button onClick={() => {toggleDrawer(); navigate('/work-profile/films');}} sx={{ justifyContent: 'center', mt: 3  }}>
            <ListItemText
            primary="FILMS WORK PROFILE"
            primaryTypographyProps={{ sx: { color: 'white !important', textAlign: 'center' } }}
            />
        </ListItem>
        <ListItem button onClick={() => {toggleDrawer(); navigate('/work-profile/tv-series');}} sx={{ justifyContent: 'center', mt: 3  }}>
            <ListItemText
            primary="TV-SERIES WORK PROFILE"
            primaryTypographyProps={{ sx: { color: 'white !important', textAlign: 'center' } }}
            />
        </ListItem>
        <ListItem button onClick={() => {toggleDrawer(); navigate('/work-profile/web-series');}} sx={{ justifyContent: 'center', mt: 3  }}>
            <ListItemText
            primary="WEB-SERIES WORK PROFILE"
            primaryTypographyProps={{ sx: { color: 'white !important', textAlign: 'center' } }}
            />
        </ListItem>
        <ListItem button onClick={() => {toggleDrawer(); navigate('/work-profile/ads');}} sx={{ justifyContent: 'center', mt: 3  }}>
            <ListItemText
            primary="ADS WORK PROFILE"
            primaryTypographyProps={{ sx: { color: 'white !important', textAlign: 'center' } }}
            />
        </ListItem>
        <ListItem button onClick={() => {toggleDrawer(); navigate('/work-profile/influencer');}} sx={{ justifyContent: 'center', mt: 3  }}>
            <ListItemText
            primary="INFLUENCER WORK PROFILE"
            primaryTypographyProps={{ sx: { color: 'white !important', textAlign: 'center' } }}
            />
        </ListItem>
        <ListItem button onClick={() => {toggleDrawer(); navigate('/contact-us');}} sx={{ justifyContent: 'center', mt: 3  }}>
            <ListItemText
            primary="CONTACT US"
            primaryTypographyProps={{ sx: { color: 'white !important', textAlign: 'center' } }}
            />
        </ListItem>
    </List>
  );


  return (
    <>
      <AppBar position="relative" sx={{ bgcolor: 'rgba(230,230,230, 0.6)',  boxShadow: '0px 2px 26px rgba(0, 0, 0, 0.5)', zIndex: 1200 }}>
        <Toolbar>
          {/* Logo on the left side */}
          <IconButton edge="start" component="a" onClick={() => navigate('/')} color=" white !important" aria-label="logo" sx={{ mr: 2 }}>
            <img src={Logo1} alt="Logo" style={{ height: 50 }} />
          </IconButton>

          {/* Title or other content */}
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}> </Typography>

          {/* Display buttons on larger screens */}
          {!isMobile && (
            <>
              <Button color="inherit" onClick={() => navigate('/')}>HOME</Button>
              <Button color="inherit" onClick={() => navigate('/actors')}>ACTORS</Button>
              <div
                // onMouseOver={handleMouseOver}
                // onMouseLeave={handleMouseLeave}
                onClick={handleMouseOver}
                ref={dropdownRef}
                style={{ position: 'relative' }}
              >
                <Button color="inherit">WORK PROFILE</Button>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  PaperProps={{
                    sx: {
                      width: '200px',
                      mt: 1.5,
                      '& .MuiMenuItem-root': {
                        fontSize: '0.875rem',
                        py: '10px',
                        px: '20px',
                      },
                    },
                  }}
                >
                  <MenuItem onClick={() => { handleClose(); navigate('/work-profile/films'); }}>Films</MenuItem>
                  <MenuItem onClick={() => { handleClose(); navigate('/work-profile/tv-series'); }}>tv-series</MenuItem>
                  <MenuItem onClick={() => { handleClose(); navigate('/work-profile/web-series'); }}>web-series</MenuItem>
                  <MenuItem onClick={() => { handleClose(); navigate('/work-profile/ads'); }}>ads</MenuItem>
                  <MenuItem onClick={() => { handleClose(); navigate('/work-profile/influencer'); }}>influencer</MenuItem>
                </Menu>
              </div>
              <Button color="inherit" onClick={() => navigate('/submission')}>SUBMISSION</Button>
              <Button color="inherit" onClick={() => navigate('/contact-us')}>CONTACT US</Button>
            </>
          )}

          {/* Menu Icon for mobile view */}
          {isMobile && (
            <IconButton edge="end" color="inherit" aria-label="menu" onClick={toggleDrawer}>
              <MenuIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>

      {/* Drawer for mobile view */}
      <Drawer
        anchor="top"
        open={drawerOpen}
        onClose={toggleDrawer}
        PaperProps={{
          sx: {
            height: '100vh',
            width: '100%',
            bgcolor: 'rgba(0, 0, 0, 0.7)', // Drawer background with less opacity
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start', // Align items to the top
            //paddingTop: '20px', // Space from the top
            transition: 'background-color 0.3s ease-in-out',
            color:'white !important',
            zIndex: theme.zIndex.drawer + 1, // Ensure the drawer is above other content
          },
        }}
      >
        <Box sx={{ width: '100%', textAlign: 'center', position: 'relative', color:'white !important' }}>
          <IconButton
            onClick={toggleDrawer}
            sx={{ color: 'white !important', position: 'absolute', top: 0, right: 10, zIndex: 1300 }}
          >
            <CloseIcon />
          </IconButton>
          {drawerItems}
        </Box>
      </Drawer>
    </>
  );
}

export default Navbar;
