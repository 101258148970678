import Cover from "./Layouts/cover";
import Videos from "./Layouts/videos";
import { useParams } from 'react-router-dom';
import { workProfileListing, bannerListing } from "project/APIs/apis";
import { useEffect, useState } from "react";
import BrandLists from "./Layouts/brandsLists";

function WorkProfile() {
  const { name } = useParams();
  
  const [items, setItems] = useState();
  const [banners, setBanners] = useState([]);

  useEffect(() =>  {
    setBanners([]);
    setItems([]);

    const fetchBannerData = async () =>{
      try{
        const banner_response = await bannerListing(name);
        if(banner_response.data.hasOwnProperty("response_code") && banner_response.data.success){
          setBanners(banner_response.data.data);
        }
      }catch(error){

      }
    };

    const fetchData = async () =>{
        try{
          const response = await workProfileListing(name);
          if(response.data.hasOwnProperty("response_code") && response.data.success){
            setTimeout(() => {
              setItems(response.data.data);
            }, 1000); 
          }
        }catch(error){

        }
    };

    fetchBannerData();
    fetchData();
  },[name]);
  
  return (
    <>
    <Cover items={banners}></Cover>
    {(name === 'films' || name === 'tv-series' || name === 'web-series' || name === 'ads')  &&(
      <Videos items={items} count={banners.length}></Videos>
    )}
   {name === 'influencer' &&(
      <BrandLists items={items} count={banners.length}></BrandLists>
    )}
    </>
  );
}

export default WorkProfile;