import axios from "axios";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
  });


export const actorListing = (param) => {
    return axiosInstance.get(`/api/actors/list?type=${param}`);
};

export const actorDetails = (ref_no) => {
    return axiosInstance.get(`/api/actors/${ref_no}`);
};

export const actorimagesDownload = (ref_no) => {
    return axiosInstance.get(`/api/actors/images/${ref_no}/download`, {
        responseType: 'blob',
      });
};

export const contactUsSubmit = (data) => {
    return axiosInstance.post('/api/contact-us/submit', data);
}

export const submissionSubmit = (data) => {
    return axiosInstance.post('/api/submission/submit', data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

export const workProfileListing = (name) => {
    return axiosInstance.get(`/api/work-profile/list/${name}`);
}

export const bannerListing = (name) => {

    return axiosInstance.get(`/api/banners/list/${name}`);
}