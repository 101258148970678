import { useState } from "react";
import { Container, Grid, Tab } from "@mui/material";
import MKBox from "components/MKBox";
import Listing from "project/Actors/Layout/listing";
import FemaleIcon from "assets/images/project/female_bg.png";
import MaleIcon from "assets/images/project/male_bg.png";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import 'project/index.css';

function Categories() {

  const [buttonActive, setbuttonActive] = useState(0);
  const [isActive, setisActive] = useState(true);
  const [key, setkey] = useState(0);

  const handleButtonClick = (value) => () => {
    setbuttonActive(value);
    setkey(Math.floor(1000 + Math.random() * 9000));
  };

  return (
    <MKBox component="section" py={{ xs: 6, md: 6, lg: 6 }} style={{ "backgroundColor": "white", "minHeight":"82vh"}}>
      <Container>
        <Grid container item justifyContent="center" xs={12} lg={7} mx="auto" textAlign="center">
          <div className="toggle-container">
            <div
              className={`toggle-wrapper ${buttonActive == 0 ? "active" : ""}`}
              onClick={handleButtonClick(0)}
            >
              <MKBox component="img" src={FemaleIcon} height="25px" pr={1}/>
              Female
            </div>
            <div
              className={`toggle-wrapper ${buttonActive == 1 ? "active" : ""}`}
              onClick={handleButtonClick(1)}
            >
              <MKBox component="img" src={MaleIcon} height="25px" pr={1}/>
              Male
            </div>
          </div>



          {/* <MKButton  color="error" onClick={handleButtonClick(0)} className={ buttonActive == 0 ? 'activeTabClass' : 'nonActiveTabClass'}>
            <MKBox component="img" src={FemaleIcon} height="50px" /> 
            <MKTypography fontWeight="bold" p={1} sx={{ color: buttonActive == 0 ? 'white !important' : '#FF0063' }}>  Female </MKTypography>
          </MKButton>
          <MKButton  color="info" onClick={handleButtonClick(1)} sx={{ ml: "10px"}} className={ buttonActive == 1 ? 'activeTabClass' : 'nonActiveTabClass'}>
            <MKBox component="img" src={MaleIcon} height="50px" /> 
            <MKTypography fontWeight="bold" p={1} sx={{ color: buttonActive == 1 ? 'white !important' : '#004AAD' }}>  Male </MKTypography>
          </MKButton> */}
        </Grid>
        <MKBox mt={{sm:2, md:1, lg:1}}  p={{xs:5, sm:3, md:2, lg:2}}>
            <Listing key={key} data={buttonActive}/>
        </MKBox>
      </Container>
    </MKBox>
  );
}

export default Categories;
