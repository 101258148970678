// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import ModelCover from "assets/images/project/model_cover_wall.webp";
import { useNavigate } from 'react-router-dom';

function FeaturesOne() {
  const navigate = useNavigate();

  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }} >
        <Container>
            <Grid container alignItems="center">
                <Grid item xs={12} lg={6}>
                    <MKBox className="sectionImg"
                        component="img" 
                        src={ModelCover} 
                        alt="model cover" 
                    />

                </Grid>
                <Grid item xs={12} lg={5} sx={{ ml: { xs: 0, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
                    <MKTypography variant="h3" my={1}>
                        ACTORS
                    </MKTypography>    
                    <MKTypography variant="body2" color="text">
                        Explore our extensive roster of talented actors, each bringing unique looks, styles, and personalities. Browse through detailed profiles, including high-quality photos and portfolios showcasing their work.
                            
                    </MKTypography>
                    <MKButton className="sectionBtn"
                        onClick={() => {window.location.href = '/actors'; }}
                        variant="outlined" 
                        color="secondary" 
                        size="large"  
                    >
                        View All <Icon sx={{ ml: 1 }}>arrow_forward</Icon>
                    </MKButton>
                </Grid>
            </Grid>
        </Container>
    </MKBox>
  );
}

export default FeaturesOne;