import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import CardInfo from "layouts/cardInfo";
import { contactUsSubmit } from 'project/APIs/apis';
import { toastError, toastSuccess} from 'shared libraries/tools';

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required('First Name is required'),
  last_name: Yup.string().required('Last Name is required'),
  phone_no: Yup.string().matches(/^[0-9]+$/, 'Phone number is not valid'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  subject: Yup.string().required('Subject is required'),
  message: Yup.string().required('Message is required'),
});

function ContactUsDetails() {
    let message = "Something went wrong. Please try again";

  const handleFormSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const response = await contactUsSubmit(values);
      if(response.data.success){
        toastSuccess(response.data.message);
        resetForm();
      }else{
        if(response.data.message){
            message = response.data.message;
        }
        toastError(message);
      }
    } catch (error) {
        if(error.response.data.message){
            message = error.response.data.message;
        }
        toastError(message);
    }
    setSubmitting(false);
  };

  return (
    <MKBox component="section" py={{ xs: 3, md: 7 }} style={{ backgroundColor: "white", boxShadow: '0px 2px 15px 0px rgba(0, 0, 0, 0.5)' }}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={5} sx={{ borderRight: { lg: '1px solid #FA6D15', xs: 'none' }, borderBottom: { xs: '1px solid #FA6D15', lg: 'none' } }}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox mb={5}>
                  <CardInfo icon="map" title="Address" description="" />
                  <MKTypography variant="body2" color="dark" pr={3}>
                    Casting Bean’s Talent Management,
                    99-studio, Behind Ambe Mata Mandir,
                    Aram Nagar, Part -2, Versova,
                    Mumbai, Maharashtra
                    400061
                  </MKTypography>
                </MKBox>
              </Grid>
              <Grid item xs={12} md={12}>
                <MKBox mb={5}>
                  <CardInfo icon="phone" title="Call Us " description=""/>
                  <MKTypography variant="body2" color="dark">
                    +91 9699724444
                  </MKTypography>
                </MKBox>
              </Grid>
              <Grid item xs={12} md={12}>
                <MKBox mb={5}>
                  <CardInfo icon="email" title="Email " description=""/>
                  {/* <MKTypography variant="body2" color="dark">
                    Male Models: castingbeansmale@gmail.com
                  </MKTypography>
                  <MKTypography variant="body2" color="dark">
                    Female Models: castingbeansfemale@gmail.com
                  </MKTypography> */}
                  <MKTypography variant="body2" color="dark">
                    castingbeanstalents@gmail.com
                  </MKTypography>
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ ml: "auto", mt: { xs: 3, lg: 0 }, boxShadow: { lg: '0px 0px 10px rgba(0, 0, 0, 0.5)', xs: 'none' }, borderRadius: '15px' }}>
            <Grid container item justifyContent="center" xs={10} lg={7} mx="auto" textAlign="center">
              <MKTypography variant="h3" mb={1}>
                Contact Us
              </MKTypography>
            </Grid>
            <Grid container item xs={12} lg={12} sx={{ mx: "auto" }}>
              <MKBox width="100%">
                <Formik
                  initialValues={{
                    first_name: '',
                    last_name: '',
                    phone_no: '',
                    email: '',
                    subject: '',
                    message: ''
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values, formikHelpers) => handleFormSubmit(values, formikHelpers)}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <MKBox p={3}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={6}>
                            <Field
                              as={MKInput}
                              variant="standard"
                              name="first_name"
                              label="First Name *"
                              fullWidth
                            />
                            <ErrorMessage name="first_name" component="div" className="contactUsError" />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Field
                              as={MKInput}
                              variant="standard"
                              name="last_name"
                              label="Last Name *"
                              fullWidth
                            />
                            <ErrorMessage name="last_name" component="div" className="contactUsError" />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Field
                              as={MKInput}
                              variant="standard"
                              name="phone_no"
                              label="Phone Number"
                              fullWidth
                            />
                            <ErrorMessage name="phone_no" component="div" className="contactUsError" />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Field
                              as={MKInput}
                              variant="standard"
                              name="email"
                              type="email"
                              label="Email Address *"
                              fullWidth
                            />
                            <ErrorMessage name="email" component="div" className="contactUsError" />
                          </Grid>
                          <Grid item xs={12}>
                            <Field
                              as={MKInput}
                              variant="standard"
                              name="subject"
                              label="Subject *"
                              fullWidth
                            />
                            <ErrorMessage name="subject" component="div" className="contactUsError" />
                          </Grid>
                          <Grid item xs={12}>
                            <Field
                              as={MKInput}
                              variant="standard"
                              name="message"
                              label="Your Message *"
                              multiline
                              fullWidth
                              rows={6}
                            />
                            <ErrorMessage name="message" component="div" className="contactUsError" />
                          </Grid>
                        </Grid>
                        <Grid container item justifyContent="center" xs={12} my={2}>
                          <MKButton type="submit" variant="gradient" color="success" fullWidth disabled={isSubmitting}>
                            Send Message
                          </MKButton>
                        </Grid>
                      </MKBox>
                    </Form>
                  )}
                </Formik>
              </MKBox>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default ContactUsDetails;
