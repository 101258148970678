import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import YtLogo from "assets/images/project/work_profile_1.webp";

function FeaturesOne() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }} style={{backgroundColor: "white", boxShadow: '0px 2px 15px 0px rgba(0, 0, 0, 0.5)'}}>
        <Container>
            <Grid container alignItems="center">
                <Grid item xs={12} lg={6} sx={{mt: { xs: 6, lg: 0 }, order: { xs: 2, lg: 1 }}}>
                    <MKTypography variant="h3" my={1}>
                        WORK PROFILE
                    </MKTypography>
                    <MKTypography variant="body2" color="text" mb={2}>
                        Discover our company's extensive work profile, featuring videos of successful collaborations with renowned brands. By viewing our work with top brands, you can see the depth of our expertise and the impactful results we achieve, ensuring confidence in choosing us for your next project.
                    </MKTypography>
                    <MKButton className="sectionBtn"
                        component="a"
                        href="/work-profile/films"
                        variant="outlined" 
                        color="secondary" 
                        size="large"
                        sx={{ ml: { xs: 2, lg: 1}, mb: {xs: 2, lg: 1} }} 
                    >
                        Films <Icon sx={{ ml: 1 }}>arrow_forward</Icon>
                    </MKButton>
                    <MKButton className="sectionBtn"
                        component="a"
                        href="/work-profile/tv-series"
                        variant="outlined" 
                        color="secondary" 
                        size="large" 
                        sx={{ ml: { xs: 2, lg: 1}, mb: {xs: 2, lg: 1} }}
                    >
                        Tv-series 
                        <Icon>arrow_forward</Icon>
                    </MKButton>
                    <MKButton className="sectionBtn"
                        component="a"
                        href="/work-profile/web-series"
                        variant="outlined" 
                        color="secondary" 
                        size="large" 
                        sx={{ ml: { xs: 2, lg: 1}, mb: {xs: 2, lg: 1} }}
                    >
                        web-series 
                        <Icon>arrow_forward</Icon>
                    </MKButton>                    
                    <MKButton className="sectionBtn"
                        component="a"
                        href="/work-profile/ads"
                        variant="outlined" 
                        color="secondary" 
                        size="large" 
                        sx={{ ml: { xs: 2, lg: 1}, mb: {xs: 2, lg: 1} }}
                    >
                        ads 
                        <Icon>arrow_forward</Icon>
                    </MKButton>
                    <MKButton className="sectionBtn"
                        component="a"
                        href="/work-profile/influencer"
                        variant="outlined" 
                        color="secondary" 
                        size="large" 
                        sx={{ ml: { xs: 2, lg: 1}, mb: {xs: 2, lg: 1} }}
                    >
                        influencer 
                        <Icon>arrow_forward</Icon>
                    </MKButton>
                    
                </Grid>
                <Grid item xs={12} lg={5} sx={{ ml: { xs: 0, lg: "auto" }, mt: { xs: 0, lg: 0 }, order: { xs: 1, lg: 2 } }}>
                    <MKBox className="sectionImg"
                        component="img" 
                        src={YtLogo} 
                        alt="youtube logo" 
                    />
                </Grid>
            </Grid>
        </Container>
    </MKBox>
  );
}

export default FeaturesOne;